import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import Icon from "./Icon";

const Privacy = ({
  show,
  onHide,
  privacyHeader,
  privacySubHeader,
  privacyContent,
  ...restProps
}) => {
  const personalInformation = privacyContent ? privacyContent.personalInformation : null;
  const personalDisclosure = privacyContent ? privacyContent.personalDisclosure : null;
  const usageCookies = privacyContent ? privacyContent.usageCookies : null;
  const dataRetention = privacyContent ? privacyContent.dataRetention : null;
  return (
    <>
      <Modal
        {...restProps}
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>{privacyHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{privacySubHeader}</Modal.Body>
        <Modal.Body>
          <h5>{personalInformation.header}</h5>
          <p>{personalInformation.subHeader}</p>
          <ul>
            {personalInformation.listItems.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>

          <h5>{personalDisclosure.header}</h5>
          <p>{personalDisclosure.subHeader}</p>
          <ul>
            {personalDisclosure.listItems.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>

          <h5>{usageCookies.header}</h5>
          <p>{usageCookies.content}</p>

          <h5>{dataRetention.header}</h5>
          <p>{dataRetention.content}</p>
        </Modal.Body>
        <Modal.Footer>
          <div className="mx-auto">
            <Button variant="primary" onClick={onHide}>
              <Icon iconName="CloseIcon" />
              &nbsp; Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

Privacy.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.any,
  privacyHeader: PropTypes.string,
  privacySubHeader: PropTypes.string,
  privacyContent: PropTypes.any,
};
Privacy.defaultProps = {
  onHide: null,
  show: null,
  privacyHeader: "",
  privacySubHeader: "",
  privacyContent: null,
};

export default Privacy;
