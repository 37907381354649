import React, { useState } from "react";
import PropTypes from "prop-types";

import { Container, Row, Col } from "react-bootstrap";
import * as SocialIcons from "components/SocialIcons";
import Privacy from "components/Privacy";
import Terms from "components/Terms";


const Footer = ({ frontmatter }) => {
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  const handleClosePrivacy = () => setShowPrivacy(false);
  const handleShowPrivacy = () => setShowPrivacy(true);
  const handleCloseTerms = () => setShowTerms(false);
  const handleShowTerms = () => setShowTerms(true);

  if (!frontmatter) {
    return null;
  }

  const {
    copyright,
    privacyText,
    termsText,
    privacy: { privacyHeader, privacySubHeader, privacyContent },
    terms: { termsHeader, termsSubHeader, termsAndConditionsContent },
    social: { facebook, github, linkedin, medium, twitter },
  } = frontmatter;

  return (
    <footer className="footer py-3 ">
      <Container fluid>
        <Row className="align-items-center  text-center">
          <Col lg={5} className="text-lg-center">
            {copyright}
          </Col>
          <Col lg={3} className="my-3 text-lg-center">
            {twitter ? <SocialIcons.Twitter userName={twitter} /> : null}
            {facebook ? <SocialIcons.Facebook userName={facebook} /> : null}
            {linkedin ? <SocialIcons.Linkedin userName={linkedin} /> : null}
            {github ? <SocialIcons.Github userName={github} /> : null}
            {medium ? <SocialIcons.Medium userName={medium} /> : null}
          </Col>
          <Col lg={4} className="text-lg-center">
            <a role="button" tabIndex={-1} className="mr-3" onClick={handleShowPrivacy}>
              {privacyText}
            </a>
            <a role="button" tabIndex={-1} className="mr-3" onClick={handleShowTerms}>
              {termsText}
            </a>
          </Col>
        </Row>
      </Container>
      <Privacy
        show={showPrivacy}
        onHide={handleClosePrivacy}
        privacyHeader={privacyHeader}
        privacySubHeader={privacySubHeader}
        privacyContent={privacyContent}
      />
      <Terms
        show={showTerms}
        onHide={handleCloseTerms}
        termsHeader={termsHeader}
        termsSubHeader={termsSubHeader}
        termsAndConditionsContent={termsAndConditionsContent}
      />
    </footer>
  );
};

Footer.propTypes = {
  frontmatter: PropTypes.object,
};

Footer.defaultProps = {
  frontmatter: null,
};

export default Footer;
