import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import Icon from "./Icon";

const Terms = ({ show, onHide, termsHeader, termsSubHeader, termsAndConditionsContent, ...restProps }) => {
  const termsDefinitions = termsAndConditionsContent ? termsAndConditionsContent.definition : null ;
  const interpretation = termsAndConditionsContent ? termsAndConditionsContent.interpretation: null;
  const acknowledgment = termsAndConditionsContent ? termsAndConditionsContent.acknowledgment: null;
  const linksToWebsites = termsAndConditionsContent ? termsAndConditionsContent.linksToWebsites: null;
  const termination = termsAndConditionsContent ? termsAndConditionsContent.termination: null;
  const liability = termsAndConditionsContent ? termsAndConditionsContent.liability: null;
  const disclaimer = termsAndConditionsContent ? termsAndConditionsContent.disclaimer: null;
  const governingLaw = termsAndConditionsContent ? termsAndConditionsContent.governingLaw: null;
  const disputes = termsAndConditionsContent ? termsAndConditionsContent.disputes: null;
  const europe = termsAndConditionsContent ? termsAndConditionsContent.europe: null;
  const us = termsAndConditionsContent ? termsAndConditionsContent.us: null;
  const severabilityWaiver = termsAndConditionsContent ? termsAndConditionsContent.severabilityWaiver: null;
  const translation = termsAndConditionsContent ? termsAndConditionsContent.translation: null;
  const termsChanges = termsAndConditionsContent ? termsAndConditionsContent.termsChanges: null;
  const contactUs = termsAndConditionsContent ? termsAndConditionsContent.contactUs: null;
  return (
    <>
      <Modal
        {...restProps}
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>{termsHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{termsSubHeader}</Modal.Body>
        <Modal.Header>
          <Modal.Title>{termsAndConditionsContent.interpretationAndDefinitionsdHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>{interpretation.header}</h5>
          <p>
            {interpretation.content}
          </p>
          <h5>{termsDefinitions.header}</h5>
           <p>{termsDefinitions.subheader}</p>
          <ul>
            {termsDefinitions.listItems.map((item) => (
              <li key={item}>{item}</li>
              ))
            }
          </ul>
          <h5>{acknowledgment.header}</h5>
          <p>
            {acknowledgment.content}
          </p>

          <h5>{linksToWebsites.header}</h5>
          <p>
            {linksToWebsites.content}
          </p>

          <h5>{termination.header}</h5>
          <p>
            {termination.content}
          </p>

          <h5>{liability.header}</h5>
          <p>
            {liability.content}
          </p>

          <h5>{disclaimer.header}</h5>
          <p>
            {disclaimer.content}
          </p>

          <h5>{governingLaw.header}</h5>
          <p>
            {governingLaw.content}
          </p>

          <h5>{disputes.header}</h5>
          <p>
            {disputes.content}
          </p>

          <h5>{europe.header}</h5>
          <p>
            {europe.content}
          </p>

          <h5>{us.header}</h5>
          <p>
            {us.content}
          </p>
          
          <h5>{severabilityWaiver.header}</h5>
          <h6>{severabilityWaiver.severability.header}</h6>
          <p>
            {severabilityWaiver.severability.content}
          </p>
          <h6>{severabilityWaiver.waiver.header}</h6>
          <p>
            {severabilityWaiver.waiver.content}
          </p>
          
          <h5>{translation.header}</h5>
          <p>
            {translation.content}
          </p>
          <h5>{termsChanges.header}</h5>
          <p>
            {termsChanges.content}
          </p>
          <h5>{contactUs.header}</h5>
          <p>
            {contactUs.content}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="mx-auto">
            <Button variant="primary" onClick={onHide}>
              <Icon iconName="CloseIcon" />
              &nbsp; Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

Terms.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.any,
  termsHeader: PropTypes.string,
  termsSubHeader: PropTypes.string,
  termsAndConditionsContent: PropTypes.any
};
Terms.defaultProps = {
  onHide: null,
  show: null,
  termsHeader: "",
  termsSubHeader: "",
  termsAndConditionsContent: null

};

export default Terms;
